:root {
  font-size: 16px;
  font-weight: $font-thin;
}

html {
  box-sizing: border-box;
  font-family: 'heisei-kaku-gothic-std', 'Roboto', 'Open Sans', sans-serif;
  font-feature-settings : "pkna";
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  font-weight: $font-thin;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

body {
  background-color: $color-background;
  min-height: 100%;
  overflow-x: hidden;
  padding: $margin-sections 0 0;
  width: 100%;

}

.index-body {
  @include min-screen($breakpoint-min-pc) {
    padding-top: 240px;
  }
}

main {
  @extend %max-width;
  min-height: 64vh;
}

.index-main {
  @extend %side-padding;
  margin-top: $margin-sections-l;
}
