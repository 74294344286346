.policy {
  margin-top: $margin-m;
}

.policy-content {
  h1 {
    color: $color-primary;
  }
  ol, ul{
    padding-inline-start: 15px;
  }

  a {
    display: inline;
  }

  .title-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $margin-xxxxl;
    position: relative;

    h1 {
      margin: 0;
    }
    h4 {
      margin-top: 10px;
    }

    &::after {
      background-color: #38b6ff;
      border-radius: 8px;
      bottom: -10px;
      content: "";
      display: block;
      height: 8px;
      justify-content: center;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 80%;
    }
  }
}

.contact-info {
  margin-top: $margin-sections;

  h2 {
    color: $color-primary;
  }

  dt:not(:first-child) {
    margin-top: $margin-m;
  }

  dt {
    color: $color-primary;
    font-weight: $font-semibold;
  }

  dd {
    margin: 0;
    padding: 0;
  }

  a {
    display: inline;
    text-decoration: underline;
  }
}
