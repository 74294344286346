.maintenance {
  @extend %max-width;
  @extend %side-padding;

  padding-top: 128px;
  position: relative;

  @include min-screen(625px) {
    padding-top: 172px;
  }

  &::before {
    background-image: url('../images/maintenance.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    height: 231px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: -24px;
    transform: translateX(-16px);
    width: 100%;
    @include min-screen(625px) {
      height: 313px;
      top: -72px;
    }
  }

  .main-message {
    margin: 0 auto $margin-xl;
    max-width: 325px;
    position: relative;
    @include min-screen(625px) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 100%;
    }
  }

  .main-message p {
    font-size: $font-size-xxxxl;
    font-weight: $font-bold;
    position: relative;
  }

  .main-message p:first-child {
    @include min-screen(625px) {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-bottom: $margin-m;
    }
  }

  .main-message .line-break-mobile {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: $margin-s;
    @include min-screen(625px) {
      display: inline-flex;
      margin-bottom: 0;
    }
  }

  .main-message .line-break-mobile::before {
    background-image: url('../images/hourmane_logo_horizontal.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 56px;
    margin-right: $margin-s;
    margin-top: -11px;
    width: 207px;
  }

  .main-message .emphasis {
    color: $color-primary;
    display: block;
    padding-bottom: $margin-s;
    position: relative;
    @include min-screen(625px) {
      display: inline-block;
    }
  }

  .main-message .emphasis::after {
    background-color: $color-primary;
    border-radius: 4px;
    bottom: 10px;
    content: '';
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 325px;
  }

  .secondary-message {
    color: $color-black;
    margin: 0 auto;
    max-width: 400px;
    @include min-screen(625px) {
      max-width: 100%;
      text-align: center;
    }
  }

  .secondary-message p {
    font-size: $font-size-m;
    
    @include min-screen(625px) {
      font-size: $font-size-l;
    }
  }

  .secondary-message p:first-child {
    margin-bottom: 1rem;

    @include min-screen(625px) {
      margin-bottom: 0;
    }
  }

  .secondary-message .emphasis {
    color: $color-primary;
    font-weight: $font-bold;
  }

  .secondary-message .line-break-pc {
    @include min-screen(625px) {
      display: block;
    }
  }
}

