@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin min-max-screen($break-point1, $break-point2) {
  @media screen and (min-width: $break-point1,) and (max-width: $break-point2) {
    @content;
  }
}