.inquiry-page-button-ghost {
  margin: 0 auto;
  width: 256px;
}

.inquiry-page-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: $margin-xxxxl;
  position: relative;

  p:first-child img {
    align-items: center;
    margin-right: $margin-s;
    width: 138px;
  }

  h1 {
    color: #4a576e;
    font-size: 26px;
    margin: 0;
  }

  &::after{
    background-color: #38b6ff;
    border-radius: 8px;
    bottom: -20px;
    content: "";
    display: block;
    height: 8px;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
  }
}

.page-title-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $margin-xxxxl;
}

.inquiry-iframe {
  border: 0;
  max-width: 100%;
  
  
  @include max-screen($breakpoint-max-tb) {
    height: 1100px;
  }
}

.form {
  margin-top: $margin-xl;
  width: 100%;
}

.Form-wrapper {
  margin: auto;
  max-width: 640px;
  padding: 0 16px;
}


.Form-Item {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}

.Form-Item-bigger-margin {
  margin-bottom: 48px;
  margin-top: 48px;
}

.Form-Item--half {
  margin-bottom: unset;
  width: calc(50% - 8px);
}

.Form-Item--flex {
  display: flex;
}

.question {
  display: flex;
  margin-bottom: 8px;
  text-align: left;
}

.Form-Item-Input,
.Form-Item-Textarea {
  border: 2px solid#999;
  border-radius: 8px;
  padding: 22px 16px 4px;
  position: relative;
  width: 100%;
}

.Form-Item-Input--select {
  padding: 22px 10px 4px;
}


.Form-Item-Label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 15px;
  transition: 0.2s ease all;
}

.Form-Item-Input:focus ~ .Form-Item-Label,
.Form-Item-Input:valid ~ .Form-Item-Label,
.Form-Item-Textarea:focus ~ .Form-Item-Label,
.hasData ~ .Form-Item-Label {
  font-size: 12px;
  top: 5px;
}

.Form-Item-Input:focus,
.Form-Item-Textarea:focus {
  border: 2px solid $color-primary;
  outline: none;
}

.Form-Item-Textarea {
  min-height: 160px;
}

.Form-Button {
  margin: $margin-xl auto $margin-l;
  width: 256px;
}

.Form-Button[type="submit"]:hover,
.Form-Button[type="submit"]:focus {
  background-color: $color-primary;
  color: #fff;
}

.Form-Button[type="submit"]:disabled,
.Form-Button[type="submit"]:disabled:hover,
.Form-Button[type="submit"]:disabled:focus {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  color: #B6BBC4;
}

.inquiry-agreement-link {
  display: inline;
}

.Form-Item__checkboxWrapperLabel {
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 8px;
}

.Form-Item__checkbox-wrapper {
  display: flex;
  flex-direction: column;
}

.register-form__label--checkbox:not(.register-form__label--checkbox--policy) {
  color: #999;
  margin-bottom: 4px;
}

