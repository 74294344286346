@import '~normalize.css/normalize.css';
@import './variables';
@import './mixin';
@import './extends';
@import './base';
@import './fonts';
@import './components/button';
@import './components/header';
@import './components/footer';
@import './pages/inquiry';
@import './pages/privacy-policy';
@import './pages/maintenance';

.hero {
  @extend %max-width;
  padding: 0 16px;
  position: relative;

  &::before {
    background-color: #fff;
    background-image: url('../images/mobile.png');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 6px;
    bottom: -32px;
    box-shadow: 0 4px 8px $color-shadow-black;
    content: '';
    display: block;
    padding-top: 35%;
    position: absolute;
    right: 6px;
    width: 16%;
    z-index: 90;
  }

  &::after {
    background-color: #fff;
    background-image: url('../images/PC.png');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 6px;
    box-shadow: 0 4px 8px $color-shadow-black;
    content: '';
    display: block;
    padding-top: 62.5%;
    width: 100%;
  }
}


.hero .catch {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  justify-content: center;
  margin: 0;
  
  @include min-screen($breakpoint-min-tb) {
    font-size: 2rem;
  }

  @include min-screen($breakpoint-min-pc) {
    font-size: 3rem;
  } 

  > span:first-child {
    margin-bottom: 4px;
  }
}

.hero .emphasis {
  color: $color-primary;
  display: inline-block;
  position: relative;

  &::after {
    background-color: $color-primary;
    border-radius: 4px;
    bottom: -6px;
    content: '';
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 55px;

    @include min-screen($breakpoint-min-tb) {
      width: 64px;
    }

    @include min-screen($breakpoint-min-pc) {
      height: 6px;
      width: 95px;
    }
  }
}

.hero .description {
  margin: $margin-m auto $margin-s;
  max-width: 334px;
  position: relative;
  width: 100%;
  
  
  @include min-screen($breakpoint-min-tb) {
    max-width: unset;
    width: 75%;
  }
  
  @include min-screen($breakpoint-min-pc) {
    margin-top: $margin-xl;
    width: 100%;
  }

  p {
    display: flex;
    justify-content: center;
  }

  .service-name {
    color: $color-primary;
    font-weight: $font-bold;
  }
}

.hero .button-hero-inquiry {
  margin: 0 auto $margin-l;
  position: relative;
  width: 226px;
}

.hero .btn-wrapper {
  margin: $margin-l 0 $margin-xxxxl;
  position: relative;

  @include min-screen($breakpoint-min-pc) {
    margin: $margin-xxl 0 $margin-xl;
  }
}

.what-is {
  background-color: $color-gray-dim;
  border-radius: 12px;
  padding: $margin-m;
}

.what-is .title {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: $font-normal;
  justify-content: center;
  margin: -2rem 0 $margin-xl;
  padding: 0;
  
  @include min-screen($breakpoint-min-tb) {
    flex-direction: row;
  }
}

.what-is .content {
  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  span {
    color: $color-alert;
    font-weight: $font-semibold;
  }
}

.benefits {
  margin-top: $margin-sections-l;

  h2 {
    font-size: 1.75rem;
    margin: 0;
    text-align: center;
  }
}

.benefits .benefits__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $margin-sections;

  @include min-screen($breakpoint-min-pc) {
    flex-direction: row;
  }

  &:nth-child(2) {
    margin-top: $margin-xxl;
  }

  &::before {
    background-image: url('../images/merit_1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 240px;
    width: 300px;

    @include min-screen($breakpoint-min-pc) {
      margin-right: $margin-l;
      min-height: 240px;
      min-width: 300px;
    }
  }
}

.benefits .benefits__wrapper--alert::before {
  background-image: url('../images/merit_2.svg');
}

.benefits .benefits__wrapper--vacation::before {
  background-image: url('../images/merit_3.svg');
}

.benefits .benefits__wrapper--evidence::before {
  background-image: url('../images/merit_4.svg');
}


.benefits .benefits__wrapper:nth-child(odd) {

  @include min-screen($breakpoint-min-pc) {
    flex-direction: row-reverse;
  }
  &::before {
    @include min-screen($breakpoint-min-pc) {
      margin-left: $margin-l;
      margin-right: 0;
    }
  }
}

.benefits__wrapper .description {
  .title{
    align-items: center;
    color: $color-primary;
    display: flex;
    font-size: 1.5rem;
    font-weight: $font-normal;
    justify-content: center;
    margin-bottom: $margin-m;
    
    @include min-screen($breakpoint-min-pc) {
      justify-content: flex-start;
    }
  }

  .paragraph-break {
    margin-top: $margin-text-line;
  }
}

.partners {
  margin-top: $margin-sections-l;

  h2 {
    font-size: 1.6rem;
    margin: 0;
    text-align: center;
  }
}

.partners__logos {
  display: flex;
  flex-direction: column;
  margin: $margin-l auto;
  @include min-screen($breakpoint-min-tb) {
    flex-direction: row;
  }
}

.partners__logo-item {
  margin: 0 auto;
  max-width: 320px;
  padding: 0;
  width: 72%;

  @include min-screen($breakpoint-min-tb) {
    width: 50%;
  }
  img {
    width: 100%;
  }
}

.partners__logo-item:first-child {
  margin-bottom: $margin-m;
}

.how-to-start {
  margin-left: auto;
  margin-right: auto;
  margin-top: $margin-sections-l;
  max-width: 500px;
}

.how-to-start h2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: $font-bold;
  justify-content: center;
  margin: 0;
  
  span {
    display: block;
    font-size: 1.5rem;
  }

  span:last-child {
    margin-top: $margin-xs;
  }
}

.how-to-start .steps {
  list-style-type: none;
  margin: 0;
  margin-top: $margin-xxl;
  padding: 0;
}


.steps .steps__item {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;

  &::before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 44px;
    margin-right: $margin-m;
    min-height: 44px;
    min-width: 44px;
    width: 44px;
  }

  &:first-child::before {
    background-image: url('../images/step_1.svg');
    margin-top: 1rem;
  }

  &:nth-child(2)::before {
    background-image: url('../images/step_2.svg');
  }

  &:last-child::before {
    background-image: url('../images/step_3.svg');
  }

  &:not(:first-child) {
    margin-top: $margin-xxxxxl;
  }

  &:first-child {
    margin-top: $margin-xxl;
  }

  &:not(:first-child)::after {
    background-image: url('../images/arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 32px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -$margin-xxxl;
    width: 64px;
  }
}

.steps__item .content-wrapper {
  .title {
    color: $color-primary;
    font-size: 1.25rem;
    font-weight: $font-normal;
    margin:.5rem 0  1rem;
  }

  .description {
    display: flex;
    flex-direction: column;
  }

  .step-note {
    color: $color-gray2;
    font-size: .8rem;
  }
}


.inquiry {
  background-image: url('../images/decoration_image.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 244px;
  margin-top: $margin-xxxxxl;
  padding-top: 275px;
  width: 100%;

  p {
    font-size: 1.5rem;
    font-weight: $font-normal;
    text-align: center;
  }

  .inquiry-text-large {
    font-size: 1.256rem;
    font-weight: $font-semibold;
    margin-bottom: 1.2rem;
  }

  .inquiry-text-normal {
    font-size: 1rem;
  }
  
  p:not(:first-child) {
    line-height: 1.1;
    margin-top: $margin-s;
  }

  p:last-child {
    color: $color-primary;
    font-size: 1.875rem;
    font-weight: $font-semibold;
  }

  p:first-child {
    align-items: flex-end;
    background-color: rgba(255,255,255,0.89);
    box-shadow: 0 0 24px 16px #fff;
    display: flex;
    justify-content: center;
  }

  p:first-child img {
    display: inline-block;
    margin-right: $margin-xs;
    width: 138px;
  }

  .button-inquiry {
    margin: $margin-xl auto 0;
    width: 226px;
  }

  .inquiry-serction-button-ghost {
    margin-top: $margin-xxl;
  }
}

.other-services {
  // margin-top: $margin-sections-l;
  margin-top: $margin-xxxxxl;

  h2 {
    font-size: 1.5rem;
    text-align: center;
  }
}

.other-services__item,
.other-services__item--visamane{
  margin-left: auto;
  margin-right: auto;
  margin-top: $margin-xxl;
  max-width: 800px;

  img {
    display: block;
    margin: 0 auto;
  }

  .description {
    margin-top: $margin-l;
  }

  .description p {
    display: flex;
    font-weight: $font-thin;
    justify-content: center;
  }

  .description p:not(:first-child) {
    margin-top: 1rem;
  }

  .link {
    font-weight: $font-thin;
    margin-top: $margin-m;
  }
}

.other-services__item--visamane {
  img {
    width: 220px;
  }
}

.t-center {
  text-align: center;
}
