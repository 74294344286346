%button-base {
  border: 0;
  border-radius: 12px;
  color: $color-white;
  display: block;
  font-size: $font-size-m;
  font-weight: 600;
  height: 44px;
  letter-spacing: 1px;
  line-height: $default-text-height;
  padding: 14px 0;
  text-align: center;
  text-decoration: none;
}


.button-primary {
  @extend %button-base;
  background: $color-primary 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 8px $color-shadow-black;

  &:hover {
    background: #7DE8FF 0% 0% no-repeat padding-box;
    color: #4D5669;
  }

  &:active {
    background: $color-visited 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: $color-white;
    transform: translateY(4px);
  }

  &:disabled,[disabled="disabled"] {
    background: $color-gray-light 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: $color-gray-dark;
  }
}

.button-ghost {
  @extend %button-base;
  border: 2px solid #38B6FF;
  color: $color-primary;

  &:hover {
    border: 2px solid #7DE8FF;
    color: #7DE8FF;
  }

  &:active {
    border: 2px solid $color-visited;
    color: $color-visited;
  }

  &:disabled,[disabled="disabled"] {
    border: 2px solid $color-gray-dark;
    color: $color-gray-dark;
  }
}

.button-secondary {
  @extend %button-base;
  background: $color-white 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 8px $color-shadow-black;
  color: $color-primary;

  &:hover {
    background: $color-primary 0% 0% no-repeat padding-box;
    color: $color-white;
  }

  &:active {
    background: $color-visited 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: $color-white;
    transform: translateY(4px);
  }

  &:disabled,[disabled="disabled"] {
    background: $color-gray-light 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: $color-gray-dark;
  }
}

.link {
  color: $color-primary;
  display: block;
  font-weight: $font-semibold;
  text-align: center;
  text-decoration: none;

  &:visited {
		color: $color-visited;
	}
}

