
$max-width: 1024px;
$header-height: 44px;
$form-base-size: 40px;
$page-content-padding-left: 220px;
$page-content-padding-right: 108px;
$page-content-padding-sidebar-hidden: 112px;

$bold: 700;

//マージン
$base-size: 8px;

//色
$color-gray1: #E5E5E5;
$color-gray2: #4a4a4a;
$color-gray3: #bfbfbf;
$color-light-yellow: #fffab1;

// Fundamental - Color
$color-primary: #38B6FF;
$color-alert: #DD012E;
$color-warning: #DDD03C;
$color-visited: #0286CB;

// Monochromatic Colors
$color-base: #000E29;
$color-gray-dark: #B6BBC4;
$color-gray-light: #F4F4F4;
$color-gray-dim: #E9EAED;
$color-shadow-black: rgba(0,0,0,.12);
$color-background: #FFF;

// blue
$color-blue: #F1F9FF;
$color-blue-brighten: #2699FB;
$color-blue-lighten: #65B8FF;
$color-blue-lighten1: #2699FB;
$color-blue-darken: #007FEB;
$color-blue-blur: #BCE0FD;

// black
$color-black: #4D5669;
$color-black-darken: #34455F;
$color-black-overlay: rgba(4,4,15,.12);

$color-white: #fff;

//ブレイクポイント
$breakpoint-max-sp: 560px;
$breakpoint-min-tb: 561px;
$breakpoint-max-tb: 960px;
$breakpoint-min-pc: 961px;


// マージンサイズ
$margin-xxxxxl: 104px;
$margin-xxxxl: 80px;
$margin-xxxl: 64px;
$margin-xxl: 48px;
$margin-xl: 32px;
$margin-l: 24px;
$margin-m: 16px;
$margin-s: 8px;
$margin-xs: 4px;
$margin-sections: 128px;
$margin-sections-l: 256px;
$margin-text-line: 1rem;
$margin-text-line-l: 1.5rem;

$line-height: 1.5;

// フォントサイズ
$font-size-xxxxl: 2rem;
$font-size-xxxl: 1.5rem;
$font-size-xxl: 1.25rem;
$font-size-xl: 1.125rem;
$font-size-l: 1rem;
$font-size-m: 0.875rem;
$font-size-s: 0.75rem;
$font-size-xs: 0.625rem;
$font-size-xxs: 0.5rem;

$default-text-height: 1.2;

// レイアウトサイズ
$max-width: 1480px;


$font-thin: 300;
$font-normal: 500;
$font-semibold: 700;
$font-bold: 900;