%max-width{
  margin-left: auto;
  margin-right: auto;

  max-width: 480px;
  width: 100%;

  @include min-screen($breakpoint-min-tb) {
    margin-left: auto;
    margin-right: auto;
    max-width: 864px;
  }
}

%side-padding {
  padding-left: $margin-m;
  padding-right: $margin-m;

  @include min-max-screen($breakpoint-min-tb, $breakpoint-max-tb) {
    padding-left: $margin-l;
    padding-right: $margin-l;
  }

  @include min-screen($breakpoint-min-pc) {
    padding-left: $margin-xl;
    padding-right: $margin-xl;
  }
}