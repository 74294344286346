footer {
  background-color: $color-gray-dark;
  color: $color-background;
  margin-top: $margin-sections-l;
  padding: $margin-xl $margin-m $margin-l;
}

.footer-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;

  .privacy {
    color: $color-background;
    font-weight: $font-normal;
    margin-top: $margin-xxxl;
    text-decoration: underline;
  }
}

.footer-title {
  font-size: 1.125rem;
  text-align: center;
}

.footer-item {
  font-size: $font-thin;
  margin-top: $margin-m;

  span {
    display: block;
  }

  &__title {
    font-weight: $font-semibold;
  }
}

.footer-item:nth-child(2) {
  margin-top: $margin-xl;
}

.copyright {
  font-size: .625rem;
  margin-top: $margin-sections;
  text-align: center;
}