header {
  background-color: $color-background;
  box-shadow: 0 0 24px 24px #fff;
  height: 50px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @include min-screen($breakpoint-min-tb) {
    height: 60px;
  }
  
  .header-wrapper {
    @extend %max-width;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 16px;
  }
}

.header-wrapper a {
  text-decoration: none;
}

header .logo {
  align-items: flex-end;
  background-image: url('../images/hourmane_logo_horizontal.svg');
  background-position: left center;
  background-repeat: no-repeat;
  color: $color-gray-dark;
  display: flex;
  font-size: .625rem;
  height: 24px;
  justify-content: flex-end;
  padding-bottom: 4px;
  width: 116px;
}

header .header-link {
  font-weight: $font-normal;
}